import { ref } from "vue";
import { UserAddressForm, UserLoginForm, UserPersonalForm } from "@/store/user/types";

type RegisterValidatorMessage = { description: string; status: "error" | "success" };

function useRegisterLoginValidators(minPasswordLength = 6) {
  const checkPassword1Result = ref<RegisterValidatorMessage | null>(null);
  const checkPassword2Result = ref<RegisterValidatorMessage | null>(null);

  function checkPassword1(password1?: string, password2?: string) {
    if (!password1) checkPassword1Result.value = { description: "Senha não digitada", status: "error" };
    else if (password1.length < minPasswordLength)
      checkPassword1Result.value = {
        description: "A senha precisa ter no mínimo 6 caracteres",
        status: "error",
      };
    else checkPassword1Result.value = null;

    if (password2) checkPassword2(password1, password2);
  }

  function checkPassword2(password1?: string, password2?: string) {
    if (password1 !== password2)
      checkPassword2Result.value = { description: "As senhas não coincidem", status: "error" };
    else checkPassword2Result.value = null;
  }

  function checkLoginForm(form: UserLoginForm | null): "error" | "success" {
    if (!form?.ds_email.includes("@")) return "error";

    checkPassword1(form?.ds_senha);
    if (checkPassword1Result.value?.status === "error") return "error";

    return "success";
  }

  return {
    checkPassword1,
    checkPassword2,
    checkPassword1Result,
    checkPassword2Result,
    checkLoginForm,
  };
}

function useRegisterPersonalValidators() {
  function checkPersonalForm(form: UserPersonalForm | null): "error" | "success" {
    if (
      !form?.nm_pessoa_fisica ||
      !form?.dt_nascimento ||
      !form?.nr_cpf ||
      !form?.nr_telefone ||
      form?.ie_sexo === null
    )
      return "error";
    return "success";
  }

  return { checkPersonalForm };
}

function useRegisterAddressValidators() {
  function checkAddressForm(form: UserAddressForm | null): "error" | "success" {
    if (!form?.cd_cidade || !form?.nr_cep || !form?.nr_numero || !form?.nm_bairro || !form?.nm_rua) return "error";
    return "success";
  }

  return { checkAddressForm };
}

export {
  useRegisterLoginValidators,
  RegisterValidatorMessage,
  useRegisterPersonalValidators,
  useRegisterAddressValidators,
};
